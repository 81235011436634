import React, { useState } from 'react';


const Contact = () => {
  const [status, setStatus] = useState("Submit");

  const [info, setInfo] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",

  });

  const handleChange = (event) => {
    setInfo({
      ...info,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(info);
    setStatus("Sending...");

    let response = await fetch(`/api/contact`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        //'Content-Type': 'application/json',
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(info),
      //body: JSON.stringify(details),
    });
    //console.log(details);
    console.log(info);
    setStatus("Submit");
    setInfo({
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });
    let result = await response.json();
    //alert(result.status);
  };

  return (
    <div>
      {/* <div className="space120"></div> */}
      <div style={{ marginTop: "50px" }}></div>
      <section id="home__Page--technology" className="homeOne__technology">
        <div
          style={{ backgroundImage: `url(images/home1/technology-expert-form/bg.png)` }}
          className="technology_banner"
        ></div>


        <div className="container">
          <div
            style={{ backgroundImage: `url(images/home1/technology-expert-form/bg2.png)`, height: "570px" }}
            className="contact_form"

          >

            <div className="space120 "></div>
            <div className="row ">
              <div className="col-lg-12 col-xl-5 ">
                <div className="img_wrapper ">
                  <img
                    src="images/home1/technology-expert-form/1.png "
                    alt=" "
                    className="img-fluid "
                    height={200}
                  />
                </div>
              </div>
              <div className="col-xl-7 ">
                <div className="heading-3 mb-20 ">Contact Us...</div>



                <form onSubmit={handleSubmit}>
                  <div className="row g-4 mb-25 ">
                    <div className="col-sm-6 ">
                      <input
                        type="text"
                        name="name"
                        pattern="[a-zA-Z\s]+"
                        id="name"
                        value={info.name}
                        onChange={handleChange}
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="email"
                        name="email"
                        pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                        id="email"
                        value={info.email}
                        onChange={handleChange}
                        placeholder="Email Address"
                        required
                      />
                    </div>
                  </div>
                  <div className="row g-4 mb-25">
                    <div className="col-sm-6">
                      <input
                        type="text"
                        name="phone"
                        pattern="\d{10}"
                        title="Please enter a valid phone number with an optional country code"
                        id="phone"
                        value={info.phone}
                        onChange={handleChange}
                        placeholder="91+"
                        required
                      />
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        name='subject'
                        id="subject"
                        value={info.subject}
                        onChange={handleChange}
                        placeholder="Your Subject"
                        required
                      />
                    </div>
                  </div>

                  <textarea name="message" id="message" placeholder="Your Comment" value={info.message} required
                    onChange={handleChange}></textarea>

                  <button className="button-primary-1 mt-25 ">
                    <span>{status} </span>
                  </button>
                  <div className="space120 "></div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
