import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = ({ onLogin }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/login', formData);
      const { role } = response.data;
      toast.success(`Login successful!`, {
        position: `top-right`,
        autoClose: 2000,
        onClose: () => {
          if (role === 'admin') {
            onLogin(true); // Call onLogin callback with true for admin login
            navigate('/');
            
          }
        }
      });
    } catch (error) {
      console.error(error);
      toast.error(`Login failed. Please check your credentials.`, { position: `top-right` });
    }
  };
  
  const handleLogout = () => {
    // Perform logout actions here
    navigate('/');
  };
  return (
    <>
      <div className="wrapper login-1" style={{ height: '400px' }}>
        <div className="lcontainer" style={{ marginLeft: '350px', marginTop: '50px', marginBottom: '50px' }}>
          <div className="col-left">
            <div className="login-text">
              <h2>Welcome Back</h2>
              <p>Create your account.<br />It's totally free.</p>
              <Link to="/register" className="btn">Sign Up</Link>
            </div>
          </div>
          <div className="col-right">
            <div className="login-form">
              <h2>Login</h2>
              <form onSubmit={handleSubmit}>
                <p>
                <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                </p>
                <p>
                <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} required />
                </p>
               
                <p>
              
                  <button type="submit">Login</button>
                </p>
                <p>
                  <a href="">Forgot Password?</a>
                </p>
              </form>
              <ToastContainer position="top-right" autoClose={5000} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
