
import { useState,useEffect } from "react";
import  { useNavigate } from "react-router-dom";
import ThankYou from "../Components/ThankYou";
import ReactGA from 'react-ga'

const Contact = () => {
    const navigate = useNavigate();
    const [status, setStatus] = useState("Submit");


    const [info, setInfo] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
    });
    useEffect(() => {
             ReactGA.pageview(window.location.pathname);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInfo({
            ...info,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        ReactGA.event({
            category : "contact us",
            action: "Submit button click",
            value : 1,
        });

        try {
            let response = await fetch(`/api/contact`, {
              method: "POST",
              headers: {
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json;charset=utf-8",
              },
              body: JSON.stringify(info),
            });
            setStatus("Submitted");
            setInfo({ name: "", email: "", phone: "", subject: "", message: "" });
            let result = await response.json();
            console.log(result);
      
            // Navigate to ThankYou page
            navigate("/ThankYou");
          } catch (error) {
            console.error("Error submitting form:", error);
          }
        };

    return (
        <>
            <main id="contact__us">

                <section style={{
                    backgroundImage: "url(/images/contact-us-banner1.jpg)",
                    backgroundColor: "rgb(255, 255, 255)"
                }}
                    class="sub-header position-relative">
                    <div class="container">
                        <div class="page-info position-absolute" >
                            <h2 class="heading-2"style={{color:"white", textAlign:"center"}}>Contact Us</h2>
                            <h6 class="heading-6">
                                <a href="/">Home</a> / <a href="#">Contact</a>
                            </h6>
                        </div>
                    </div>
                </section>

                <section id="touch" class="container">

                    <div class="section-heading d-flex flex-column align-items-center justify-content-center">
                        <div class="heading-2">Get In Touch</div>
                        <br />

                       
                    </div>

                    <div class="touch__contact">
                        <div class="row g-4">
                            <div class="col-lg-4">
                                <div class="touch__contact--address touch__card" style={{ height: "249px" }}>
                                    <div class="touch__contact--address--icon icon">
                                        <img src="images/address.png" alt="address" />
                                    </div>
                                    <span class="touch__contact--address--header header">
                                        Address
                                    </span>
                                    <span class="touch__contact--address--desc desc paragraph">
                                        B133/1, 2nd Floor, KSSIDC ITI Estate, Whitefield Main Rd, Mahadevapura, Bengaluru, Karnataka 560048
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="touch__contact--phone--number touch__card active" style={{ height: "249px" }}>
                                    <div class="touch__contact--address--icon icon">
                                        <img src="images/telephone.png" alt="telephone" />
                                    </div>
                                    <span class="touch__contact--address--header header paragraph">
                                        Phone Number
                                    </span>
                                   
                                        {/* <div>91  (636) 465 7660</div> */}
                                        <a  onclick="return gtag_report_conversion('tel:+916364657660');" href="tel:+916364657660" >+91 63646 57660
</a> <div><a href="tel:+919900502404" >+91 99005 02404
</a></div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="touch__contact--mail touch__card" style={{ height: "249px" }}>
                                    <div class="touch__contact--address--icon icon">
                                        <img src="images/mail.png" alt="address" />
                                    </div>
                                    <div class="touch__contact--address--header header paragraph">
                                        Send us mail
                                    </div>
                                    <div class="touch__contact--address--desc desc paragraph">
                                        <div ><a href="mailto:info@excerptech.com">info@excerptech.com</a></div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="space120"></div>

                <section id="contact" class="container">
                    <div class="row g-4">
                        <div class="col-lg-7">
                            <div class="contact__form">
                                <div class="contact__form--header mb-20">
                                    Have any Question On Mind!
                                </div>
                                <div class="contact__form--desc mb-25">
                                    Your email address will not be published. Required fields are marked
                                </div>

                               <div>
                  {status !== "Submitted" && (
                    <form onSubmit={handleSubmit}>
                      <div className="row g-4 mb-25">
                        <div className="col-sm-6">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            value={info.name}
                            onChange={handleChange}
                            placeholder="Your Name"
                            required
                          />
                        </div>
                        <div className="col-sm-6">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            value={info.email}
                            onChange={handleChange}
                            placeholder="Email Address"
                            required
                          />
                        </div>
                      </div>
                      <div className="row g-4 mb-25">
                        <div className="col-sm-6">
                          <input
                            type="tel"
                            name="phone"
                            id="phone"
                            value={info.phone}
                            onChange={handleChange}
                            placeholder="91+"
                            required
                          />
                        </div>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            name="subject"
                            id="subject"
                            value={info.subject}
                            onChange={handleChange}
                            placeholder="Your Subject"
                            required
                          />
                        </div>
                      </div>

                      <textarea
                        name="message"
                        id="message"
                        placeholder="Your Comment"
                        required
                        value={info.message}
                        onChange={handleChange}
                      ></textarea>

                      <button className="button-primary-1 mt-25 ">
                        <span>{status}</span>
                      </button>
                    </form>
                  )}
                </div>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="contact__map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.5670664396944!2d77.69618407356857!3d12.999519214268583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11e5beced50b%3A0x6dfbb6145c02cc8b!2sExcerpt%20Technologies%20Private%20Limited!5e0!3m2!1sen!2sin!4v1705125132851!5m2!1sen!2sin"
                                    style={{ border: "0", allowfullscreen: "", loading: "lazy", referrerpolicy: "no-referrer-when-downgrade" }}></iframe>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="space120"></div>

                {status === "Submitted" && <ThankYou />}
            </main>

        </>
    )
}

export default Contact
