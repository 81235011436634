import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Ecommerce from './Pages/Ecommerce';
import Webdesign from './Pages/Webdesign';
import Services from './Pages/Services';
import Mobileapp from './Pages/Mobileapp';
import Cybersecurity from './Pages/Cybersecurity';
import DigitalMarketing from './Pages/DigitalMarketing';
import Seo from './Pages/Seo';
import Dataanalytics from './Pages/Dataanalytics';
import Contact from './Pages/Contact';
import Whatsapp from './Components/Whatsapp';
import Login from './Pages/Login';
import Privacy from './Components/Privacy';
import Home from './Pages/Home';
import Portfolio from './Pages/Portfolio';
import Uiux from './Pages/Uiux';
import Scroll from './Components/Scroll';
import Carrers from './Pages/Carrers';
import JobData from './Components/JobData';
import ResumeComponent from './Components/Resumes';
import AdminHome from './Components/AdminHome';
import Dashboard from './Pages/Dashboard';
import Register from './Pages/Register';
import Certificate from './Pages/Certificate';
import Topheader from './Components/Topheader';
import ThankYou from './Components/ThankYou';
import Cookie from './Components/Cookie/Cookie'
import Popupform from './Components/PopupScroll/Popupform'
import CertificateDetail from './Components/CertificateDetail';
import CertificatePage from './Components/CertificatePage';
import Training from './Pages/Training/Training';
import Onlineprogram from './Pages/Training/OnlineProgram/Onlineprogram';
import Internship from './Pages/Training/Internship/Internship';
import Placement from './Pages/Training/Placement/Placement';
import InternProject from './Pages/Training/Internship/InternProject';
import Allprogram from './Pages/Training/Allprogram/Allprogram'
import PopupImg from './Pages/Training/PopupImage/PopupImg';
import {Helmet} from "react-helmet";


const ProtectedRoute = ({ element, isLoggedIn, path }) => {
  return isLoggedIn ? element : <Navigate to="/login" />;
};
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Check if admin is logged in from sessionStorage
    const isAdminLoggedIn = sessionStorage.getItem('isAdminLoggedIn');
    if (isAdminLoggedIn) {
      setIsAdmin(true);
      setIsLoggedIn(true); // Assuming admin login also means user is logged in
    }
  }, []);
  const students = [
    // Your students array here
  ];

  const handleLogin = () => {
    // Your login logic here
    setIsLoggedIn(true);
    setIsAdmin(true);
    // Store admin login state in sessionStorage
    sessionStorage.setItem('isAdminLoggedIn', 'true');
  };

  const handleLogout = () => {
    // Your logout logic here
    setIsLoggedIn(false);
    setIsAdmin(false);
    sessionStorage.removeItem('isAdminLoggedIn');
  };

  return (
    <>
      <Router>

        <Topheader isLoggedIn={isLoggedIn} onLogout={handleLogout} />
        <Navbar isLoggedIn={isLoggedIn} isAdmin={isAdmin} onLogout={handleLogout} />
        {/* <Popupform /> */}
        <Helmet>
           <title>EXCERPT TECHNOLOGIES PRIVATE LIMITED - Software As A Service Provider| Best Software Solution Provider</title>
           <meta name="description" content="Welcome to Excerpt Technologies Private Limited. We specialize in Web Design & Development, E-commerce Solutions, Data Analytics, and BI Report Generation. Partner with us to elevate your digital presence with innovative and customized solutions. Contact us today for expert IT services!" />
           <maeta name="keywords" content="best software solutions company,
best retail software solutions,
business software solutions,
digital software solution,
it solutions provider,
remote software solutions,
software & digital solutions,
software business solutions consulting,
software design solutions,
software solution providers,
software solutions,
software solutions and designs,
software solutions bangalore,
software solutions company,
software solutions company in bangalore,
software solutions company names,
best software developers in bengaluru,
software solutions expert,
software solutions for company,
software solutions for small business,
software solutions services "/>
        </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/ecommerce" element={<Ecommerce />} />
          <Route path="/webdesign" element={<Webdesign />} />
          <Route path="/mobileapplication" element={<Mobileapp />} />
          <Route path="/CyberSecurity" element={<Cybersecurity />} />
          <Route path="/DigitalMarketing" element={<DigitalMarketing />} />
          <Route path="/seo" element={<Seo />} />
          <Route path="/Dataanalytics" element={<Dataanalytics />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/uiux" element={<Uiux />} />
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/careers" element={<Carrers />} />
          <Route path="/JobData" element={<JobData />} />
          <Route path="/resumes" element={<ResumeComponent />} />
          <Route path="/Admin" element={<AdminHome />} />
          <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} isLoggedIn={isLoggedIn} />} />
          <Route path="/certii" element={<Certificate />} />
          <Route path="/cert" element={<Certificate />} />
          <Route path="/ThankYou" element={<ThankYou />} />
          <Route path="/trainings" element={<Training />} />
          <Route path="/certificate/:regNo" element={<CertificatePage />} />
          <Route path="/certificate/:regNo" element={<CertificateDetail />}  />
          <Route path="/Onlineprogram" element={<Onlineprogram/>}  />
          <Route path="/Internship" element={<Internship/>}/>
          <Route path="/Placement" element={<Placement/>}/>
          <Route path="/Allprogram" element={<Allprogram/>}/>
        
        </Routes>
        <Scroll />
      </Router>
      <Whatsapp />
     
      <Cookie />
      <Footer />
    </>
  );
}

export default App;
