import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion';
import '../Allprogram/Allprogram.css'; // Import the CSS file

const coursesData = [
    {
        id: 1,
        title: 'Digital Marketing',
        image: "/Trainingimage/dm1.jpg",
        description: 'A most powerful tool to grow business and increase reach worldwide.                               ',
        link: '/digitalmarketing'
    },
    {
        id: 2,
        title: 'Data Science with Python',
        image: '/Trainingimage/datas.jpeg',
        description: 'Data science with Python performs data analysis, data visualization.',
        link: '/python',
    },
    {
        id: 3,
        title: 'JAVA',
        image: '/Trainingimage/jva1.jpeg',
        description: 'Data Science with R provides the skills required to work with real data sets',
        link: '/corejava',
    },
    {
        id: 4,
        title: 'Mern Stack Development',
        image: '/Trainingimage/mern.png',
        description: 'MEAN is an acronym for MongoDB, Express.js and Angularjs, all of which function upon Node.js',
        link: '/mernstack',
    },
    // Add more course objects as needed
    {
        id: 5,
        title: '.NET',
        image: '/Trainingimage/net.png',
        description: 'Most powerful, secure, and flexible end-to-end analytics platform for data',
        link: '/.net',
    },
    {
        id: 6,
        title: 'Python',
        image: '/trainingimage/py1.jpeg',
        description: 'high-level programming language with dynamic semantics developed by Guido van Rossum.',
        link: '/python',
    },
    {
        id: 7,
        title: 'Ethical Hacking',
        image: '/Trainingimage/hck.jpeg',
        description: 'o legally break into computers and networks to test an organizations overall security',
        link: '/ethicalhacking',
    },
    {
        id: 8,
        title: 'Fullstack Developer',
        image: '/Trainingimage/jfsd.png',
        description: 'a professional responsible for working on both front-end and back-end development processes.',
        link: '/fullstack',
    },
    {
        id: 9,
        title: 'C Sharp',
        image: '/Trainingimage/csharp.jpeg',
        description: 'a professional responsible for working on both front-end and back-end development processes.',
        link: '/csharp',
    },

    {
        id: 10,
        title: 'C++ programming',
        image: '/Trainingimage/c++.png',
        description: 'a professional responsible for working on both front-end and back-end development processes.',
        link: '/cprogramming',
    },

    {
        id: 11,
        title: 'Android',
        image: '/Trainingimage/andr.jpeg',
        description: 'a professional responsible for working on both front-end and back-end development processes.',
        link: '/android',
    },

    {
        id: 12,
        title: ' Python Fullstack Development ',
        image: '/Trainingimage/pfsd.jpeg',
        description: 'a professional responsible for working on both front-end and back-end development processes.',
        link: '/fullstack',
    },
    {
        id: 13,
        title: ' PHP',
        image: '/Trainingimage/php.png',
        description: 'a professional responsible for working on both front-end and back-end development processes.',
        link: '/php',
    },
    {
        id: 14,
        title: ' Selenium Testing',
        image: '/Trainingimage/tst.jpeg',
        description: 'a professional responsible for working on both front-end and back-end development processes.',
        link: '/selenium',
    },
    {
        id: 15,
        title: ' AWS ',
        image: '/Trainingimage/aws.jpeg',
        description: 'a professional responsible for working on both front-end and back-end development processes.',
        link: '/aws',
    },
    {
        id: 16,
        title: ' MYSQL',
        image: '/Trainingimage/sql.jpeg',
        description: ' fully managed database service for transactions, real- time analytics across data warehouses.',
        link: '/mysql',
    },
    {
        id: 17,
        title: ' Basic MS Office',
        image: '/Trainingimage/msofce.png',
        description: ' fully managed database service for transactions, real- time analytics across data warehouses.',
        link: '/msoffice',
    },
    {
        id: 18,
        title: ' Advance EXCEL',
        image: '/Trainingimage/excel.png',
        description: ' fully managed database service for transactions, real- time analytics across data warehouses.',
        link: '/aexcel',
    },
    {
        id: 19,
        title: ' Tally ERP9 with GST ',
        image: '/Trainingimage/tally.png',
        description: ' fully managed database service for transactions, real- time analytics across data warehouses.',
        link: '/tally',
    },
    {
        id: 20,
        title: 'SAP- FICO ',
        image: '/Trainingimage/sap.jpeg',
        description: ' fully managed database service for transactions, real- time analytics across data warehouses.',
        link: '/sap',
    },

];

const TrendingCourses = () => {
    return (
        <Fragment>
             <div className="tab-container resposnive_allprogram" >
                    
             <button style={{margin:"10px",backgroundColor:"#0554f2"}}>
                        <Link to="/Allprogram" style={{color:"white"}}>Allprogram</Link>
                    </button>
                    <button style={{margin:"10px",backgroundColor:"#0554f2"}}>
                        <Link to="/Onlineprogram" style={{color:"white"}}>Onlineprogram</Link>
                    </button>
                    <button style={{margin:"10px",backgroundColor:"#0554f2"}}>
                        <Link to="/Internship" style={{color:"white"}}>Internship</Link>
                    </button>
                    <button style={{margin:"10px",backgroundColor:"#0554f2"}}>
                        <Link to="/Placement" style={{color:"white"}}>Placement</Link>
                    </button>
                </div>
            
            <div className="trending-courses">
                {/* <br /><h1>IT DESK - Courses</h1><br /> */}

                <br />
                <div className="course-list">
                    {coursesData.map(course => (
                        <motion.div
                            className="course-card"
                            key={course.id}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <img src={course.image} alt={course.title} />
                            <h2 style={{textAlign:"center",textTransform:"uppercase"}}>{course.title}</h2>
                            <p>{course.description}</p>
                            <a href="#" target="_blank" rel="noopener noreferrer">
                                <button className="button">Learn More</button>
                            </a>
                            {/* <Link to={course.link} >Learn More</Link> */}
                        </motion.div>
                    ))}
                </div>
            </div>
        </Fragment>
    );
};

export default TrendingCourses